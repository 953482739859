define('frontend/models/product', ['exports', 'ember-data/model', 'frontend/mixins/expireable', 'ember-data/attr', 'ember-data/relationships', 'moment'], function (exports, _model, _expireable, _attr, _relationships, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var PRICE_SORTING = ['created_at'];

  exports.default = _model.default.extend(_expireable.default, {
    priceSorting: PRICE_SORTING,

    label: (0, _attr.default)('string'),
    created_at: (0, _attr.default)('moment', {
      defaultValue: function defaultValue() {
        return (0, _moment.default)();
      }
    }),

    category: (0, _relationships.belongsTo)('category', { inverse: 'products' }),
    prices: (0, _relationships.hasMany)('prices', { inverse: 'product' }),

    category_expired_at: Ember.computed.readOnly('category.expired_at'),
    sortedPrices: Ember.computed.sort('prices', 'priceSorting'),

    latestPrice: Ember.computed('prices.@each.created_at', function () {
      return this.get('sortedPrices').get('lastObject');
    }),

    priceFor: function priceFor(order) {
      var prices = this.get('sortedPrices').filter(function (price) {
        return Ember.isPresent(price.get('created_at')) && price.get('created_at').isBefore(order.get('created_at'));
      });
      return prices.get('lastObject');
    }
  });
});