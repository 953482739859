define('frontend/templates/orders/new', ['exports', 'frontend/templates/orders/edit'], function (exports, _edit) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _edit.default;
    }
  });
});