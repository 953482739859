define('frontend/routes/orders/new', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model(_ref) {
      var _this = this;

      var user_id = _ref.user_id;

      return Ember.RSVP.hash({
        order: this.get('store').findRecord('user', user_id).then(function (user) {
          return _this.get('store').createRecord('order', {
            user: user,
            inlay_f: 0
          });
        }),
        categories: this.get('store').findAll('category')
      });
    },
    afterModel: function afterModel(_ref2) {
      var order = _ref2.order,
          categories = _ref2.categories;

      return Ember.RSVP.all([Ember.RSVP.all(categories.mapBy('products')).then(function (productsArray) {
        var products = productsArray.reduce(function (acc, cur) {
          return acc.addObjects(cur);
        }, []);
        return Ember.RSVP.all(products.mapBy('prices'));
      }), order.get('user')]);
    }
  });
});