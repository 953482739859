define('frontend/components/product-button', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: '',

    price: Ember.computed('model.prices.@each.{price_f,created_at}', 'order.created_at', function () {
      return this.get('model').priceFor(this.get('order'));
    })
  });
});