define('frontend/models/order-item', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships'], function (exports, _model, _attr, _relationships) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    amount: (0, _attr.default)('number'),

    mutation: (0, _relationships.belongsTo)('mutation', { inverse: 'order_items', polymorphic: true }),
    product: (0, _relationships.belongsTo)('product', { inverse: null }),

    cost: Ember.computed('product.prices.[]', 'amount', function () {
      var price = this.get('product.content').priceFor(this.get('mutation'));
      return (Ember.isPresent(price) ? price.get('price_f') : 0) * this.get('amount');
    }),

    negatedAmount: Ember.computed('amount', {
      get: function get() {
        return this.get('amount') * -1;
      },
      set: function set(_key, value) {
        this.set('amount', value * -1);
        return value;
      }
    }),

    summary: Ember.computed('amount', 'product.label', function () {
      return this.get('amount') + 'x ' + this.get('product.label');
    })
  });
});