define('frontend/models/mutation', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships', 'moment'], function (exports, _model, _attr, _relationships, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    change_f: (0, _attr.default)('number'),
    created_at: (0, _attr.default)('moment', {
      defaultValue: function defaultValue() {
        return (0, _moment.default)();
      }
    }),

    order_items: (0, _relationships.hasMany)('order-item'),

    cost: Ember.computed('order_items.@each.cost', function () {
      return this.get('order_items').reduce(function (total, orderItem) {
        return total + orderItem.get('cost');
      }, 0);
    }),

    summary: Ember.computed('order_items.@each.summary', function () {
      return this.get('order_items').mapBy('summary').join(', ');
    }),

    filterCategories: function filterCategories(categories) {
      return this.filterItems(categories);
    },
    filterProducts: function filterProducts(products) {
      return this.filterItems(products);
    },
    filterItems: function filterItems(items) {
      var _this = this;

      return items.filter(function (item) {
        return item.get('created_at').isBefore(_this.get('created_at')) && (Ember.isNone(item.get('expired_at')) || item.get('expired_at').isAfter(_this.get('created_at')));
      });
    },
    save: function save() {
      var _this2 = this;

      return this._super.apply(this, arguments).then(function () {
        var promise = _this2.get('order_items').reduce(function (chain, orderItem) {
          return chain.then(function () {
            if (orderItem.get('amount') !== 0) {
              orderItem.save();
            } else {
              Ember.RSVP.resolve();
            }
          });
        }, Ember.RSVP.resolve());
        if (Ember.isPresent(_this2.get('user'))) {
          _this2.set('user.balance_f', _this2.get('user.balance_f') + _this2.get('change'));
        }
        return promise;
      });
    }
  });
});