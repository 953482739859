define('frontend/mixins/expireable', ['exports', 'ember-data/attr', 'moment'], function (exports, _attr, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    expired_at: (0, _attr.default)('moment'),

    expire: function expire() {
      this.set('expired_at', (0, _moment.default)());
      return this.save();
    }
  });
});