define('frontend/components/num-pad', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var NUMBERS = [[1, 2, 3], [4, 5, 6], [7, 8, 9]];

  exports.default = Ember.Component.extend({
    tagName: '',
    numbers: NUMBERS
  });
});