define('frontend/components/storage-mutation-row', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: '',

    currentAmount: Ember.computed('row.lastObject', 'orderItem.negatedAmount', function () {
      return (this.get('row.lastObject') || 0) * -1 + parseInt(this.get('orderItem.negatedAmount'));
    }),

    orderItem: Ember.computed('row.firstObject', 'mutation.order_items.[]', function () {
      return this.get('mutation.order_items').findBy('product.label', this.get('row.firstObject'));
    })
  });
});