define('frontend/controllers/admin/storage-mutations', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    storageMutationsNew: Ember.inject.controller('admin.storage-mutations.new'),

    newMutation: Ember.computed.readOnly('storageMutationsNew.model')
  });
});