define('frontend/routes/admin/products/new', ['exports', 'ember-data-route'], function (exports, _emberDataRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_emberDataRoute.default, {
    model: function model(_ref) {
      var _this = this;

      var category_id = _ref.category_id;

      return this.get('store').findRecord('category', category_id).then(function (category) {
        return _this.get('store').createRecord('product', {
          category: category
        });
      });
    }
  });
});