define('frontend/routes/orders/edit', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model(_ref) {
      var order_id = _ref.order_id;

      return Ember.RSVP.hash({
        order: this.get('store').findRecord('order', order_id),
        categories: this.get('store').findAll('category')
      });
    },
    afterModel: function afterModel(_ref2) {
      var order = _ref2.order,
          categories = _ref2.categories;

      return Ember.RSVP.all([Ember.RSVP.all(categories.mapBy('products')).then(function (productsArray) {
        var products = productsArray.reduce(function (acc, cur) {
          return acc.addObjects(cur);
        }, []);
        return Ember.RSVP.all(products.mapBy('prices'));
      }), order.get('user'), order.get('order_items').then(function (order_items) {
        return order_items.mapBy('product');
      }).then(function (products) {
        return Ember.RSVP.all(products.mapBy('prices'));
      })]);
    }
  });
});