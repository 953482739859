define('frontend/routes/external/users/show', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model(_ref) {
      var external_id = _ref.external_id;

      return this.get('store').queryRecord('user', {
        external_id: external_id
      });
    },
    afterModel: function afterModel(model) {
      return model.get('orders');
    }
  });
});