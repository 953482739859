define('frontend/routes/admin/overviews/show', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({

    queryParams: {
      startDate: {
        refreshModel: true
      },
      endDate: {
        refreshModel: true
      }
    },

    model: function model(_ref) {
      var overview_id = _ref.overview_id,
          startDate = _ref.startDate,
          endDate = _ref.endDate;

      return this.get('store').queryRecord('overview', {
        overview_id: overview_id,
        start_date: startDate,
        end_date: endDate
      });
    }
  });
});