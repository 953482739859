define('frontend/routes/admin/categories/show', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model(_ref) {
      var category_id = _ref.category_id;

      return this.get('store').findRecord('category', category_id);
    },
    afterModel: function afterModel(model) {
      return model.get('product');
    }
  });
});