define('frontend/controllers/admin/users/show', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var ORDER_SORTING = ['created_at:desc'];

  exports.default = Ember.Controller.extend({
    orderSorting: ORDER_SORTING,
    tokenSorting: ORDER_SORTING,

    sortedOrders: Ember.computed.sort('model.orders', 'orderSorting'),
    sortedTokens: Ember.computed.sort('model.user.user_tokens', 'tokenSorting'),

    total: Ember.computed('model.orders.@each.change_f', function () {
      return (this.get('model.orders') || []).reduce(function (total, order) {
        return order.get('change_f') + total;
      }, 0);
    }),

    actions: {
      expire: function expire(token) {
        token.set('expired_at', (0, _moment.default)());
        token.save();
      },
      newToken: function newToken() {
        var token = this.get('store').createRecord('user-token', {
          user: this.get('model.user')
        });
        token.save();
      }
    }
  });
});