define('frontend/models/user', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships', 'ember-api-actions'], function (exports, _model, _attr, _relationships, _emberApiActions) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    name: (0, _attr.default)('string'),
    balance_f: (0, _attr.default)('number'),
    order: (0, _attr.default)('number'),

    orders: (0, _relationships.hasMany)('order'),
    user_tokens: (0, _relationships.hasMany)('user-token'),

    importUsers: (0, _emberApiActions.collectionAction)({
      path: 'import',
      type: 'post'
    })
  });
});