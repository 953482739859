define('frontend/controllers/admin/products/edit', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    actions: {
      save: function save() {
        var _this = this;

        this.get('model').save().then(function () {
          _this.transitionToRoute('admin.products.show', _this.get('model'));
        });
      },
      expire: function expire() {
        var _this2 = this;

        if (confirm('Weet je zeker dat je ' + this.get('model.label') + ' wilt verwijderen? Dit kan niet ongedaan gemaakt worden!')) {
          this.get('model').expire().then(function () {
            return _this2.transitionToRoute('admin.products');
          });
        }
      }
    }
  });
});