define('frontend/router', ['exports', 'frontend/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Router = Ember.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL
  });

  Router.map(function () {
    this.route('users', function () {});

    this.route('orders', function () {
      this.route('new');
      this.route('edit', { path: '/:order_id/edit' });
    });

    this.route('external', function () {
      this.route('login');
      this.route('users', function () {
        this.route('show', { path: '/:external_id' });
      });
    });

    this.route('admin', function () {
      this.route('categories', function () {
        this.route('new');
        this.route('show', { path: '/:category_id' });
        this.route('edit', { path: '/:category_id/edit' });
      });

      this.route('overviews', function () {
        this.route('show', { path: '/:overview_id' });
      });

      this.route('products', function () {
        this.route('new');
        this.route('show', { path: '/:product_id' });
        this.route('edit', { path: '/:product_id/edit' });
      });

      this.route('storage-mutations', function () {
        this.route('new');
        this.route('show', { path: '/:storage_mutation_id' });
      });

      this.route('users', function () {
        this.route('new', { path: '/new' });
        this.route('show', { path: '/:user_id' });
      });
    });
  });

  exports.default = Router;
});