define('frontend/controllers/admin/products/show', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    price: 0.0,

    actions: {
      enterPrice: function enterPrice() {
        this.get('store').createRecord('price', {
          product: this.get('model'),
          price_f: this.get('price')
        }).save();
      }
    }
  });
});