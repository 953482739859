define('frontend/controllers/admin/overviews/show', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    startDate: '1970-01-01T00:00',
    endDate: '2050-01-01T00:00',

    queryParams: ['startDate', 'endDate']
  });
});