define('frontend/controllers/admin/users', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    actions: {
      new: function _new() {
        this.transitionToRoute('admin.users.new');
      }
    }
  });
});