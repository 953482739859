define('frontend/routes/application', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    intl: Ember.inject.service(),
    auth: Ember.inject.service(),
    router: Ember.inject.service(),

    beforeModel: function beforeModel() {
      this._super.apply(this, arguments);
      this.get('intl').setLocale('nl-nl');
      return this.get('auth').require('admin');
    },
    redirect: function redirect() {
      if (!this.get('auth').has('admin') && !window.location.href.includes('external')) {
        this.transitionTo('external.login');
      }
    }
  });
});