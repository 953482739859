define('frontend/components/screen-keyboard', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var keys = ['QWERTYUIOP', 'ASDFGHJKL', 'ZXCVBNM'];

  exports.default = Ember.Component.extend({
    tagName: '',

    keyRows: Ember.computed(function () {
      return keys.map(function (row) {
        return row.split('');
      });
    }),

    actions: {
      enter: function enter(char) {
        this.set('value', '' + this.get('value') + char);
      },
      backspace: function backspace() {
        this.set('value', this.get('value').substring(0, this.get('value.length') - 1));
      }
    }
  });
});