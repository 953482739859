define('frontend/controllers/admin/storage-mutations/new', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  exports.default = Ember.Controller.extend({
    currentStorage: Ember.computed('model.{total.rows.[],mutation.order_items.@each.amount}', function () {
      var _this = this;

      return this.get('model.total.rows').map(function (_ref) {
        var _ref2 = _slicedToArray(_ref, 2),
            label = _ref2[0],
            amount = _ref2[1];

        var orderItem = _this.get('model.mutation.order_items').findBy('product.label', label);
        var change = Ember.isPresent(orderItem) ? parseInt(orderItem.get('amount')) : 0;
        return {
          label: label,
          amount: (amount || 0) * -1 + change,
          orderItem: orderItem
        };
      });
    }),

    actions: {
      save: function save() {
        this.get('model.mutation').save().then(function () {
          window.location.reload();
        });
      }
    }
  });
});