define('frontend/controllers/orders/edit', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var CATEGORY_SORTING = ['label'];

  exports.default = Ember.Controller.extend({
    queryParams: ['user_id'],

    categorySorting: CATEGORY_SORTING,

    _selectedCategory: null,
    selectedOrderItem: null,
    isInlaySelected: false,
    freshSelection: false,

    categories: Ember.computed.sort('activeCategories', 'categorySorting'),
    selectedCategory: Ember.computed.or('_selectedCategory', 'categories.firstObject'),

    activeCategories: Ember.computed('model.categories.@each.{created_at,expired_at}', 'model.order.created_at', function () {
      return this.get('model.order').filterCategories(this.get('model.categories'));
    }),

    actions: {
      selectTab: function selectTab(category) {
        this.set('_selectedCategory', category);
      },
      enterProduct: function enterProduct(product) {
        var orderItem = this.get('model.order.order_items').findBy('product.id', product.get('id'));
        if (Ember.isPresent(orderItem)) {
          orderItem.incrementProperty('amount');
        } else {
          orderItem = this.get('store').createRecord('order-item', {
            mutation: this.get('model.order'),
            product: product,
            amount: 1
          });
        }
      },
      selectItem: function selectItem(orderItem) {
        this.set('isInlaySelected', false);
        this.set('selectedOrderItem', orderItem);
        this.set('freshSelection', true);
      },
      removeItem: function removeItem(orderItem) {
        if (this.get('selectedOrderItem') === orderItem) {
          this.set('selectedOrderItem', null);
          this.set('freshSelection', false);
        }
        orderItem.deleteRecord();
      },
      enterNumber: function enterNumber(number) {
        if (Ember.isNone(this.get('selectedOrderItem')) && !this.get('isInlaySelected')) {
          return;
        }
        if (this.get('isInlaySelected')) {
          if (this.get('freshSelection')) {
            this.set('freshSelection', false);
            this.set('model.order.inlay_f', parseInt(number) * 0.01);
          } else {
            this.set('model.order.inlay_f', parseInt(Math.round(this.get('model.order.inlay_f') * 10000 / 100).toString() + number.toString()) * 0.01);
          }
        } else {
          if (this.get('freshSelection')) {
            this.set('freshSelection', false);
            this.set('selectedOrderItem.amount', parseInt(number));
          } else {
            this.set('selectedOrderItem.amount', parseInt(this.get('selectedOrderItem.amount').toString() + number.toString()));
          }
        }
      },
      confirm: function confirm() {
        var _this = this;

        if (this.checkBalance()) {
          this.get('model.order').save().then(function () {
            _this.send('dismiss');
          });
        }
      },
      dismiss: function dismiss() {
        this.get('model.order').rollbackAttributes();
        this.get('model.order.order_items').forEach(function (order_item) {
          return order_item.rollbackAttributes();
        });
        this.transitionToRoute('admin.users.show', this.get('model.order.user.id'));
      },
      selectInlay: function selectInlay() {
        this.set('isInlaySelected', true);
        this.set('selectedOrderItem', null);
        this.set('freshSelection', true);
      }
    },

    checkBalance: function checkBalance() {
      if (this.get('model.order.user.balance_f') < 0) {
        return confirm('Deze persoon staat rood. Weet je zeker dat je dit order wilt plaatsen?');
      } else if (this.get('model.order.user.balance_f') + this.get('model.order.change') < 0) {
        return confirm('Door dit order komt deze persoon rood te staan. Weet je zeker dat je dit order wilt plaatsen?');
      } else {
        return true;
      }
    }
  });
});