define('frontend/routes/admin/categories/new', ['exports', 'ember-data-route'], function (exports, _emberDataRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_emberDataRoute.default, {
    model: function model() {
      return this.get('store').createRecord('category');
    }
  });
});