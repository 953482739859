define('frontend/controllers/users', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    query: '',

    users: Ember.computed('model', 'query', function () {
      var _this = this;

      if (Ember.isBlank(this.get('query'))) {
        return this.get('model');
      } else {
        return this.get('model').filter(function (user) {
          return user.get('name').toLowerCase().includes(_this.get('query').toLowerCase());
        });
      }
    }),

    actions: {
      backspace: function backspace() {
        this.set('query', this.get('query').substring(0, this.get('query.length') - 1));
      },
      clear: function clear() {
        this.set('query', '');
      },
      order: function order(user) {
        this.send('clear');
        this.transitionToRoute('orders.new', { queryParams: { user_id: user.get('id') } });
      }
    }
  });
});