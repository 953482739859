define('frontend/controllers/admin/products', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    products: Ember.computed('model.@each.{expired_at,category_expired_at}', function () {
      return this.get('model').filter(function (product) {
        return Ember.isNone(product.get('expired_at')) && Ember.isNone(product.get('category.expired_at'));
      });
    })
  });
});