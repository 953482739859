define('frontend/models/storage-mutation', ['exports', 'frontend/models/mutation'], function (exports, _mutation) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _mutation.default.extend({
    change: Ember.computed.readOnly('cost'),

    type: 'storage-mutation'
  });
});