define('frontend/routes/admin/products/show', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model(_ref) {
      var product_id = _ref.product_id;

      return this.get('store').findRecord('product', product_id);
    },
    afterModel: function afterModel(model) {
      return model.get('prices');
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      controller.set('price', model.get('latestPrice.price_f'));
    }
  });
});