define('frontend/controllers/admin/users/new', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    actions: {
      save: function save() {
        var _this = this;

        this.get('model').save().then(function () {
          _this.transitionToRoute('admin.users.show', _this.get('model.id'));
        });
      }
    }
  });
});