define('frontend/routes/admin/users/show', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({

    queryParams: {
      startDate: {
        refreshModel: true
      },
      endDate: {
        refreshModel: true
      }
    },

    model: function model(_ref) {
      var user_id = _ref.user_id,
          startDate = _ref.startDate,
          endDate = _ref.endDate;

      return Ember.RSVP.hash({
        user: this.get('store').findRecord('user', user_id),
        orders: this.get('store').query('order', {
          start_date: startDate,
          end_date: endDate,
          user_id: user_id
        })
      });
    },
    afterModel: function afterModel(_ref2) {
      var user = _ref2.user;

      return user.get('orders').then(function (orders) {
        return Ember.RSVP.all(orders.mapBy('order_items'));
      }).then(function (order_items) {
        var items = order_items.reduce(function (acc, order_items) {
          return acc.addObjects(order_items);
        }, []);
        return Ember.RSVP.all(items.mapBy('product'));
      });
    }
  });
});