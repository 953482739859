define('frontend/models/category', ['exports', 'ember-data/model', 'frontend/mixins/expireable', 'ember-data/attr', 'ember-data/relationships', 'moment'], function (exports, _model, _expireable, _attr, _relationships, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend(_expireable.default, {
    label: (0, _attr.default)('string'),
    created_at: (0, _attr.default)('moment', {
      defaultValue: function defaultValue() {
        return (0, _moment.default)();
      }
    }),

    products: (0, _relationships.hasMany)('products', { inverse: 'category' })
  });
});