define('frontend/models/price', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships'], function (exports, _model, _attr, _relationships) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    price_f: (0, _attr.default)('number'),
    created_at: (0, _attr.default)('moment'),

    product: (0, _relationships.belongsTo)('product', { inverse: 'prices' })
  });
});