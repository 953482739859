define('frontend/controllers/external/users/show', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var ORDER_SORTING = ['created_at:desc'];

  exports.default = Ember.Controller.extend({
    orderSorting: ORDER_SORTING,
    startDate: '1970-01-01T00:00',
    endDate: '2050-01-01T00:00',

    queryParams: ['startDate', 'endDate'],

    sortedOrders: Ember.computed.sort('model.orders', 'orderSorting'),

    total: Ember.computed('model.orders.@each.change_f', function () {
      return this.get('model.orders').reduce(function (total, cur) {
        return total + cur.get('change_f');
      }, 0);
    })
  });
});