define('frontend/services/auth', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    ajax: Ember.inject.service(),
    scopes: null,

    init: function init() {
      this._super.apply(this, arguments);
      this.set('scopes', []);
    },
    require: function require(scope) {
      var _this = this;

      return this.get('ajax').post('/auth/enact', {
        data: {
          token: localStorage['accessToken'],
          scope: scope
        }
      }).then(function (response) {
        if (response.status === 'ok') {
          _this.scopes.pushObject(scope);
        }
        return Ember.RSVP.resolve();
      });
    },
    has: function has(scope) {
      return this.get('scopes').includes(scope);
    },
    token: function token() {
      return localStorage['accessToken'];
    }
  });
});