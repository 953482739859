define('frontend/controllers/orders/new', ['exports', 'frontend/controllers/orders/edit'], function (exports, _edit) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _edit.default.extend({
    actions: {
      dismiss: function dismiss() {
        if (this.get('model.order.isNew')) {
          this.get('model.order').deleteRecord();
        }
        this.transitionToRoute('users');
      }
    }
  });
});