define('frontend/transforms/moment', ['exports', 'ember-data/transform', 'moment'], function (exports, _transform, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _transform.default.extend({
    deserialize: function deserialize(serialized) {
      return serialized ? (0, _moment.default)(serialized) : null;
    },
    serialize: function serialize(deserialized) {
      return deserialized ? deserialized.toJSON() : null;
    }
  });
});