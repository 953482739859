define('frontend/models/order', ['exports', 'frontend/models/mutation', 'ember-data/attr', 'ember-data/relationships'], function (exports, _mutation, _attr, _relationships) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _mutation.default.extend({
    inlay_f: (0, _attr.default)('number'),

    user: (0, _relationships.belongsTo)('user'),

    change: Ember.computed('cost', 'inlay_f', function () {
      return this.get('inlay_f') - this.get('cost');
    }),

    type: 'order'
  });
});