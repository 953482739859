define('frontend/adapters/application', ['exports', 'ember-data/adapters/json-api', 'ember-inflector'], function (exports, _jsonApi, _emberInflector) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _jsonApi.default.extend({
    auth: Ember.inject.service(),

    init: function init() {
      this._super.apply(this, arguments);
      this.set('headers', {
        'Authorization': 'Bearer ' + this.get('auth').token()
      });
    },
    pathForType: function pathForType(type) {
      return (0, _emberInflector.pluralize)(type).underscore();
    }
  });
});