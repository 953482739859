define('frontend/controllers/admin/categories/show', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    actions: {
      newProduct: function newProduct() {
        this.transitionToRoute('admin.products.new', { queryParams: { category_id: this.get('model.id') } });
      }
    }
  });
});