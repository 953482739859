define('frontend/controllers/admin/categories', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    categories: Ember.computed.filterBy('model', 'expired_at', null),

    actions: {
      new: function _new() {
        this.transitionToRoute('admin.categories.new');
      }
    }
  });
});