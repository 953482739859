define('frontend/models/user-token', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships'], function (exports, _model, _attr, _relationships) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    created_at: (0, _attr.default)('moment'),
    expired_at: (0, _attr.default)('moment'),
    token: (0, _attr.default)('string'),

    user: (0, _relationships.belongsTo)('user')
  });
});