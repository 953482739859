define('frontend/controllers/external/login', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    actions: {
      login: function login() {
        localStorage['accessToken'] = this.get('accessToken');
        location.href = '/';
      }
    }
  });
});