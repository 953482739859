define('frontend/serializers/application', ['exports', 'ember-data/serializers/json-api'], function (exports, _jsonApi) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _jsonApi.default.extend({
    keyForAttribute: function keyForAttribute(key) {
      return key;
    },
    keyForRelationship: function keyForRelationship(key) {
      return key;
    }
  });
});