define('frontend/routes/admin/storage-mutations/new', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model() {
      var _this = this;

      return Ember.RSVP.hash({
        total: this.get('store').queryRecord('overview', {
          overview_id: 'products',
          start_date: '1970-01-01',
          end_date: '2050-01-01'
        }),
        products: this.get('store').findAll('product')
      }).then(function (_ref) {
        var total = _ref.total,
            products = _ref.products;

        var mutation = _this.get('store').createRecord('storage-mutation');
        products.forEach(function (product) {
          return _this.get('store').createRecord('order-item', {
            mutation: mutation,
            product: product,
            amount: 0
          });
        });
        return {
          mutation: mutation,
          total: total
        };
      });
    }
  });
});